@import "../abstract/mixins";

.about {
  margin-inline: auto;
  height: 70em;
  width: 50em;
  padding-top: 1em;
  border-radius: 5px;
  background-color: #dbd8d0;
  margin-top: 4em;
  .hero-image {
    margin-bottom: 2em;
  }
  h2 {
    margin-left: 2.5em;
    font-size: 2em;
    margin-bottom: -1.5em;
    font-weight: bold;
  }
  p {
    width: 80ch;
    margin-left: 4.5em;
  }
  strong {
    font-weight: 600;
  }
  @include fromTablet {
    height: 98em;
    width: 22em;
    .hero-image {
      img {
        height: 16em;
      }
    }
    h2 {
      margin-left: 1em;
    }
    p {
      width: 35ch;
      margin-left: 1.5em;
    }
  }
}
