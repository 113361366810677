@import "../abstract/mixins";

.hero {
  .hero-nav {
    height: 2em;
    display: flex;
    margin-inline: 10em;
    align-items: center;

    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 5px;
    justify-content: space-around;
    background: rgb(240, 241, 242);
    background: linear-gradient(
      0deg,
      rgba(240, 241, 242, 1) 50%,
      rgba(255, 255, 255, 1) 50%
    );

    .index.active,
    a.active {
      background: linear-gradient(
        0deg,
        rgba(100, 153, 21, 1) 49%,
        rgba(140, 184, 75, 1) 50%
      );
    }

    .line {
      height: 100%;
      width: 1px;
      background-color: black;
    }
    a {
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 3px 0px 0px 3px;
    }
    li:hover {
      height: 100%;
      background: rgb(100, 153, 21);

      background: linear-gradient(
        0deg,
        rgba(100, 153, 21, 1) 49%,
        rgba(140, 184, 75, 1) 50%
      );
    }
    // .index {
    //   background: rgb(100, 153, 21);
    //   background: linear-gradient(
    //     0deg,
    //     rgba(100, 153, 21, 1) 49%,
    //     rgba(140, 184, 75, 1) 50%
    //   );
    // }
  }

  .hero-image {
    width: 40.5em;
    margin-top: 3em;
    margin-inline: auto;
    padding: 0.3em;
    border: 1px solid black;
    position: relative;
    img {
      height: 22em;
      width: 40em;
    }
  }
  .contact-details {
    height: 4em;
    width: 20em;
    font-weight: 500;
    padding-inline: 2em;
    padding-block: 0.5em;
    background-color: #8cb84b;
    position: absolute;
    color: white;
    left: -0.5em;
    top: 1.5em;
    z-index: 9;
  }
  .line {
    height: 1px;
    width: 45em;
    background-color: black;
    // margin-inline: auto;
    margin-block: 3em;
  }
  @include fromTablet {
    .hero-nav {
      background-color: red;
      height: 2.5em;
      width: 20em;
      margin-inline: auto;
      font-size: 0.8em;
      a {
        font-size: 0.7em;
        padding-top: 1em;
      }
    }
  }
}
