@import "../abstract/mixins";

.services {
  margin-inline: auto;
  margin-bottom: 3em;
  width: 50em;
  .flex {
    display: flex;
    gap: 2em;
  }
  h1 {
    font-size: 2em;
    color: red;
    font-weight: bold;
  }

  li {
    list-style-type: disc;
    font-size: 0.8em;
    margin-left: 2em;
  }
  .box {
    height: 16em;
    width: 25em;
    border: 5px solid #7b736f;
  }
  .heading {
    margin-top: 0.5em;
    margin-left: -1em;
    height: 2em;
    width: 20em;
    padding-left: 2em;
    padding-top: 0.3em;
    background-color: #8cb84b;
  }
  @include fromTablet {
    width: 22em;
    .flex {
      display: block;
    }
  }
  h1 {
    margin-left: 1.5em;
  }
  .box {
    height: 17.5em;
    width: 20em;
    margin-top: 1em;
    margin-inline: auto;
  }
  .heading {
    font-size: 0.7em;
    width: 25em;
  }
}
