@import "../abstract/mixins";

.footer {
  height: 5em;
  width: 100%;
  font-size: 0.8em;
  background-color: #4f210b;
  display: flex;
  color: white;
  justify-content: space-around;
  text-align: center;
  margin-top: 0.5em;
  display: block;

  a {
    color: green;
    margin-left: 0em;
  }
  @include fromTablet {
    height: 4em;
    p {
      font-size: 0.6em;
    }
    a {
      font-size: 0.8em;
      margin-inline: auto;
    }
  }
}
