@import "../abstract/mixins";

.client {
  height: 40em;
  width: 50em;
  margin-inline: auto;
  margin-top: 3em;
  padding-top: 2em;
  border-radius: 5px;
  background-color: #d8d5cd;
  h1 {
    font-size: 2em;
    margin-left: 1.3em;
    font-weight: bold;
  }
  .line {
    height: 1px;
    width: 45em;
    background-color: black;
    margin-block: 1em;
    margin-inline: auto;
  }
  ul {
    li {
      list-style-type: disc;
      margin-left: 3em;
      line-height: 2em;
    }
  }
  p {
    margin-left: 2em;
  }
  @include fromTablet {
    width: 22em;
    height: 45em;
    .line {
      width: 20em;
    }
    ul {
      li {
        width: 35ch;
        margin-left: 1.5em;
      }
    }
  }
}
