@import "../abstract/mixins";

.hero-image {
  width: 40.5em;
  margin-top: 3em;
  margin-inline: auto;
  padding: 0.3em;
  border: 1px solid black;
  position: relative;
  img {
    height: 22em;
    width: 40em;
  }
  @include fromTablet {
    width: 20em;
    img {
      height: 20em;
      width: 24em;
    }
  }
}
.contact-details {
  height: 4em;
  width: 20em;
  font-weight: 500;
  padding-inline: 2em;
  padding-block: 0.5em;
  background-color: #8cb84b;
  position: absolute;
  color: white;
  left: -0.5em;
  top: 1.5em;
  z-index: 9;
  @include fromTablet {
    height: 7em;
    font-size: 0.8em;
  }
}
