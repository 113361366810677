@import "../abstract/mixins";

.vision {
  width: 40em;
  margin-inline: auto;
  h2 {
    font-size: 1.5em;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    color: red;
    margin-block: 1em;
  }
  li {
    list-style-type: disc;
    margin-left: 1em;
  }
  .main-line {
    height: 1px;
    width: 50em;
    background-color: black;
    margin-left: -5em;
    margin-block: 3em;
  }
  span {
    color: green;
    font-weight: bold;
  }
  @include fromTablet {
    width: 20em;
    .main-line {
      width: 20em;
      margin-inline: auto;
    }
  }
}
