@import "../abstract/mixins";

.navbar {
  height: 5em;
  width: 100%;
  background-color: #aad0f2;
  .logo {
    display: flex;
    align-items: center;
    gap: -5em;

    .logo-image {
      height: 4em;
      margin-left: 2em;
      margin-block: 0.5em;
    }
  }
  @include fromTablet {
    height: 3em;
    .logo {
      .logo-image {
        // width: 100%;
        height: 2.2em;
        margin-left: 1em;
        margin-block: 0.5em;
      }
    }
  }
}
