@import "../abstract/mixins";

.contact-us {
  height: 40em;
  width: 50em;
  padding-inline: 3em;
  margin-top: 3em;
  padding-top: 2em;
  border-radius: 5px;
  margin-inline: auto;
  background-color: #d8d5cd;
  .contact-wrap {
    display: flex;
    margin-bottom: 2em;
    gap: 4em;
    h1 {
      font-size: 1.8em;
      margin-bottom: 1em;
      color: red;
    }
    h2 {
      font-weight: bold;
    }
    span {
      color: red;
      font-weight: bold;
    }
    p {
      font-size: 0.8em;
    }
    input {
      display: block;
      margin-bottom: 0.5em;
      background-color: #352618;
      width: 20em;
      padding-left: 1em;
      color: white;
    }

    input:hover {
      background-color: #675959;
    }
    textarea {
      display: block;
      margin-bottom: 0.5em;
      background-color: #352618;
      min-width: 20em;
      max-width: 20em;
      min-height: 7em;
      max-height: 7em;
      padding-left: 1em;
      color: white;
    }
    textarea:hover {
      background-color: #675959;
    }
    button {
      background-color: #ef6423;
      border: none;
      width: 7em;
      height: 2em;
      border-radius: 5px;
      margin-left: 13em;
      color: white;
    }
    button:hover {
      background-color: #8cb84b;
    }
  }
  .map {
    height: 16.3em;
    width: 43em;
    border: 5px solid #649915;
  }

  @include fromTablet {
    height: 50em;
    width: 23em;
    padding-left: 1em;
    .contact-wrap {
      display: block;
    }
    .map {
      height: 8em;
      width: 20em;
      img {
        width: 24em;
      }
    }
    input {
      margin-top: 1em;
      // max-width: 18em;
    }
    textarea {
      margin-top: 1em;
      max-width: 18em;
    }
  }
}
