@mixin lightTheme {
  --clr-primary-400: hsl(48, 97%, 60%);
  --clr-primary-300: hsl(49, 90%, 92%);
  --clr-primary-text-400: hsl(213, 15%, 14%);
  --clr-primary-text-300: hsl(214, 10%, 57%);
}

@mixin fromTablet {
  @media (max-width: 50em) {
    @content;
  }
}

@mixin fromDesktop {
  @media (max-width: 80.3125em) {
    @content;
  }
}
